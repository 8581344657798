import { PusherChannel } from '../types/PusherChannel';
import { Token } from '../../auth/types/Token';
import PusherWrapper from './PusherWrapper';

class PusherService {
  public logPrefix = '[Pusher]';
  private _pusher!: PusherWrapper;

  get pusher() {
    if (!this._pusher) {
      throw new Error(`${this.logPrefix} No valid configuration found!`);
    }

    return this._pusher;
  }

  init = (pusherChannel: PusherChannel, token: Token) => {
    this._pusher = new PusherWrapper(pusherChannel, token);
  };
}

export default new PusherService();
