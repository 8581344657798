import React from 'react';
import GlobalSpinner from '../../../shared/components/Spinners/GlobalSpinner/GlobalSpinner';
import LogService from '../../../core/logger/services/LogService';
import useAction from '../../../shared/hooks/action/useAction';
import LanguageProviderService from './services/LanguageProviderService';

const LanguageProvider: React.FC = ({ children }) => {
  const [{ isLoading, error }] = useAction(
    LanguageProviderService.loadLanguageFromUrl,
  );

  if (error) {
    LogService.error('[LanguageProvider] Setting language failed', error);
  }

  if (isLoading) {
    return <GlobalSpinner />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default LanguageProvider;
