export enum ResponseCode {
  OK = 'OK',
  NOK = 'NOK',
  WARN = 'WARN',
}

type Response<T extends {}, C extends ResponseCode> = {
  code: C;
} & T;

type ResponseWithMessage<T extends {}, C extends ResponseCode> = Response<
  T,
  C
> & {
  message: string;
};

type OkResponse<T> = Response<T, ResponseCode.OK>;
type WarnResponse<T> = ResponseWithMessage<T, ResponseCode.WARN>;
type NokResponse<T> = ResponseWithMessage<{}, ResponseCode.NOK>;

export type PostMessageResponseContent<T> =
  | OkResponse<T>
  | WarnResponse<T>
  | NokResponse<T>;
