import OnboardingService from '../../onboarding/services/OnboardingService';
import LogService from '../../logger/services/LogService';
import replaceWith from '../../router/utils/replaceWith';
import { Routes } from '../../router/types/Routes';
import navigateTo from '../../router/utils/navigateTo';
import QrProviderService from './QrProviderService';

class FlowPickerService {
  chooseFlow = (): void => {
    const qr = QrProviderService.loadQrFromUrl();

    if (OnboardingService.isOnboardingNeeded()) {
      LogService.log('[FlowPicker] Starting onboarding flow');
      OnboardingService.start();
      return;
    }

    if (qr) {
      LogService.log('[FlowPicker] Starting scan flow');
      replaceWith(Routes.HOME);
      navigateTo(Routes.SCAN, { base64qr: btoa(qr) });
      return;
    }

    LogService.log('[FlowPicker] Starting default flow');
    replaceWith(Routes.HOME);
    return;
  };
}

export default new FlowPickerService();
