import Pusher from 'pusher-js';
import { PusherChannel } from '../types/PusherChannel';
import { Token } from '../../auth/types/Token';
import { JOYN_KBC_BACKEND_URL, PUSHER_KEY } from '../../constants/constants';
import { PusherEvent } from '../types/PusherEvent';
import LogService from '../../logger/services/LogService';
import SentryService from '../../sentry/services/SentryService';
import { WebsocketError } from '../types/PusherError';

Pusher.log = LogService.log;

class PusherWrapper {
  public logPrefix = '[Pusher]';

  private pusher: Pusher.Pusher;
  private pusherChannel: PusherChannel;

  constructor(pusherChannel: PusherChannel, token: Token) {
    LogService.log(`${this.logPrefix} Initialising`);

    this.pusherChannel = pusherChannel;
    this.pusher = new Pusher(PUSHER_KEY, {
      cluster: 'eu',
      authEndpoint: `${JOYN_KBC_BACKEND_URL}/users/me/pusher-auth`,
      forceTLS: true,
      auth: {
        headers: { Authorization: `Bearer ${token}` },
      },
    });
    this.pusher.connection.bind('error', this.handleError);
  }

  private handleError = (error: WebsocketError) => {
    LogService.error(error);
    SentryService.captureMessage(error.error.data.message, error);
  };

  /*
   FIXME
   subscription will subscribe to 1 event on 1 channel.
   Unsubscribe will remove all event handlers for channel.
   This results in a mismatch between intents
  */
  subscribe = <T>(
    eventName: PusherEvent,
    onMessageReceived: (message: T) => void,
  ) => {
    LogService.log(`${this.logPrefix} Subscribing`);

    this.pusher.subscribe(this.pusherChannel).bind(eventName, (message: T) => {
      LogService.log(`${this.logPrefix} Received`, message);
      onMessageReceived(message);
    });
  };

  unsubscribe = () => {
    LogService.log(`${this.logPrefix} Unsubscribing`);

    this.pusher.unsubscribe(this.pusherChannel);
  };

  disconnect = () => {
    LogService.log(`${this.logPrefix} Disconnecting`);

    this.pusher.disconnect();
  };
}

export default PusherWrapper;
