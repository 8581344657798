import React, { useEffect } from 'react';
import PostMessageService from '../../../../services/PostMessageService';
import { Routes } from '../../../../../router/types/Routes';
import navigateTo from '../../../../../router/utils/navigateTo';
import { ResponseCode } from '../../../../types/PostMessageResponse/PostMessageResponseContent';

type Props = {};

const ScanHandler: React.FC<Props> = () => {
  useEffect(
    () =>
      PostMessageService.onReceive.scan(async ({ response }) => {
        if (
          response.code === ResponseCode.OK ||
          response.code === ResponseCode.WARN
        ) {
          navigateTo(Routes.SCAN, { base64qr: btoa(response.text) });
        }
      }),
    [],
  );

  return null;
};

export default ScanHandler;
