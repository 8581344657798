import TranslateService from '../../../services/TranslateService';
import getQueryParamFromUrl from '../../../../shared/utils/getQueryParamFromUrl';

class LanguageProviderService {
  loadLanguageFromUrl = async () => {
    const language = getQueryParamFromUrl('language');

    if (language) {
      await TranslateService.setLanguage(language.toLowerCase());
    }
  };
}

export default new LanguageProviderService();
