import React from 'react';
import Spinner from '../Spinner/Spinner';
import styles from './GlobalSpinner.module.scss';

const GlobalSpinner: React.FC = () => {
  return (
    <div className={styles.loading}>
      <Spinner />
    </div>
  );
};

export default GlobalSpinner;
