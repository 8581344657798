import { Token } from '../types/Token';
import LogService from '../../logger/services/LogService';

class TokenService {
  logPrefix = '[Token]';

  token?: Token;

  get payloadPart(): string | undefined {
    if (!this.token) {
      return undefined;
    }

    const jwtTokenParts = this.token.split('.');

    return jwtTokenParts[1];
  }

  getUserIdFromToken = (): string | undefined => {
    const payloadPart = this.payloadPart;

    if (!payloadPart) {
      LogService.warn(`${this.logPrefix} Token is missing payload`);
      return;
    }

    try {
      const decodedPayload = atob(payloadPart);
      const tokenPayloadObject = JSON.parse(decodedPayload);
      return tokenPayloadObject.sub;
    } catch (e) {
      LogService.warn(
        `${this.logPrefix} Token does not contain a valid user id`,
      );
      return;
    }
  };
}

export default new TokenService();
