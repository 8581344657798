import * as Sentry from '@sentry/browser';
import {
  BUILD_NUMBER,
  ENV_NAME,
  SENTRY_APP_RELEASE,
  SENTRY_DSN_KEY,
} from '../../constants/constants';
import LogService from '../../logger/services/LogService';

class SentryService {
  logPrefix = '[Sentry]';

  config = {
    dsn: SENTRY_DSN_KEY,
    release: SENTRY_APP_RELEASE,
    dist: BUILD_NUMBER,
    environment: ENV_NAME,
  };

  init = (): void => {
    LogService.info(`${this.logPrefix} Starting`);

    if (!this.config.dsn) {
      LogService.warn(`${this.logPrefix} Disabled due to missing DSN`);
      return;
    }

    try {
      Sentry.init(this.config);

      LogService.info(`${this.logPrefix} Running`);
    } catch (e) {
      LogService.warn(`${this.logPrefix} Disabled due to error`, e);
    }
  };

  captureException = (error: unknown) => Sentry.captureException(error);

  captureMessage = (message: string, data?: Record<string, unknown>) => {
    Sentry.withScope((scope) => {
      if (data) {
        Object.keys(data).forEach((key) => {
          scope.setExtra(key, data[key]);
        });
      }
      Sentry.captureMessage(message);
    });
  };
}

export default new SentryService();
