import ReactGA, { GaOptions } from 'react-ga';
import { sha256 } from 'js-sha256';
import LogService from '../logger/services/LogService';
import { history } from '../router/history/history';
import { ANALYTICS_TRACKING_CODE } from '../constants/constants';

class AnalyticsService {
  public logPrefix = '[Analytics]';

  init = (userId: string): void => {
    if (!ANALYTICS_TRACKING_CODE) {
      LogService.log(`${this.logPrefix} Not initialised: No tracking code`);
      return;
    }

    const hashedUserId = sha256(userId);
    this.initReactGA(ANALYTICS_TRACKING_CODE, hashedUserId);
    this.setupAutoPageViewTracking();
  };

  trackPageView = () => {
    ReactGA.pageview(history.location.pathname + history.location.search);
  };

  private initReactGA = (trackingCode: string, userId: GaOptions['userId']) => {
    LogService.log(
      `${this.logPrefix} Initialising with tracking code and userId`,
    );

    ReactGA.initialize(trackingCode, {
      gaOptions: {
        storage: 'none',
        storeGac: false,
        userId: userId,
      },
    });
    ReactGA.set({ anonymizeIp: true });
  };

  private setupAutoPageViewTracking = () => {
    history.listen(this.trackPageView);

    LogService.log(`${this.logPrefix} Tracking pageViews on location change`);
  };
}

export default new AnalyticsService();
