import { useEffect, useState } from 'react';
import { Action, ActionError, ActionResult } from './types/Action';

const useAction = <P extends unknown[], R>(
  action: Action<P, R>,
  ...params: P
): ActionResult<R> => {
  const [retryId, setRetryId] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<ActionError>();
  const [content, setContent] = useState<R>();

  useEffect(() => {
    let isCancelled = false;

    const callback = async () => {
      setIsLoading(true);
      setError(undefined);
      setContent(undefined);

      try {
        const result = await action(...params);

        if (!isCancelled) {
          setContent(result);
        }
      } catch (e) {
        if (!isCancelled) {
          setError(e);
        }
      } finally {
        if (!isCancelled) {
          setIsLoading(false);
        }
      }
    };

    callback();

    return () => {
      isCancelled = true;
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [retryId, action, ...params]);

  const retry = () => {
    if (!isLoading) {
      setRetryId((id) => id + 1);
    }
  };

  return [
    {
      isLoading,
      error,
      content,
    },
    { retry },
  ];
};

export default useAction;
