import { Routes } from '../../router/types/Routes';
import LogService from '../../logger/services/LogService';
import UserRepository from '../../../repository/user/UserRepository';
import replaceWith from '../../router/utils/replaceWith';

class OnboardingService {
  logPrefix = '[Onboarding]';
  showTermsAndConditions: boolean = true;

  isOnboardingNeeded = () => {
    return this.showTermsAndConditions;
  };

  start = () => {
    LogService.log(`${this.logPrefix} Start`);

    if (this.showTermsAndConditions) {
      LogService.log(`${this.logPrefix} Redirecting to terms and conditions`);
      replaceWith(Routes.TERMS_AND_CONDITIONS);
      return;
    }

    LogService.log(`${this.logPrefix} Done`);
    replaceWith(Routes.HOME);
  };

  acceptTermsAndConditions = async () => {
    LogService.log(`${this.logPrefix} Accepting terms and conditions`);

    const { showTermsAndConditions } = await UserRepository.patchUser({
      conditionsAccepted: true,
    });

    this.showTermsAndConditions = showTermsAndConditions;

    this.start();
  };
}

export default new OnboardingService();
