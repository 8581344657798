/* istanbul ignore next */
import { lazy } from 'react';

export const LazyHome = lazy(() => import('../../../views/Home/Home'));
/* istanbul ignore next */
export const LazyScan = lazy(() => import('../../../views/Scan/Scan'));
/* istanbul ignore next */
export const LazyTermsAndConditions = lazy(() =>
  import('../../../views/TermsAndConditions/TermsAndConditions'),
);
/* istanbul ignore next */
export const LazyShopDetail = lazy(() =>
  import('../../../views/ShopDetail/ShopDetail'),
);
/* istanbul ignore next */
export const LazyShopDetailWithCheckin = lazy(() =>
  import('../../../views/ShopDetail/ShopDetailWithCheckin'),
);
/* istanbul ignore next */
export const LazyShopDetailWithLoyaltyToken = lazy(() =>
  import('../../../views/ShopDetail/ShopDetailWithLoyaltyToken'),
);
/* istanbul ignore next */
export const LazyFindAMerchant = lazy(() =>
  import('../../../views/FindAMerchant/FindAMerchantContainer'),
);
