import PostMessageService from '../../postMessage/services/PostMessageService';
import { Routes } from '../types/Routes';
import currentRouteIsOneOf from './currentRouteIsOneOf';
import goBack from './goBack';

export const exitRoutes: Routes[] = [
  Routes.HOME,
  Routes.TERMS_AND_CONDITIONS,
  Routes.ROOT,
];

const goBackOrExit = (): void => {
  if (currentRouteIsOneOf(exitRoutes)) {
    PostMessageService.send.close();
  } else {
    goBack();
  }
};

export default goBackOrExit;
