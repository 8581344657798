import { Pagination } from '../types/Pagination';
import AxiosService from '../services/AxiosService';
import { SessionInfo } from './types/SessionInfo';
import { VisitedShop } from './types/VisitedShop';
import { OnboardingInfo } from './types/OnboardingInfo';
import { OnboardingInfoResponse } from './types/OnboardingInfoResponse';
import { Activity } from './types/Activity';

class UserRepository {
  origin = 'joyn-kbc-mobile-web-app';
  tokenUrl = `/token`;
  userUrl = `/users`;

  authorizeWithKBCAuthCode = (authCode: string) => {
    const params = { token: authCode, origin: this.origin };

    return AxiosService.post<SessionInfo>(this.tokenUrl, params);
  };

  getVisitedShops = (page: number) => {
    const url = `${this.userUrl}/me/shops`;
    const params = { page, size: 20 };

    return AxiosService.get<Pagination<VisitedShop>>(url, { params });
  };

  getActivity = (page: number) => {
    const url = `${this.userUrl}/me/activity`;
    const params = { page, size: 20 };

    return AxiosService.get<Pagination<Activity>>(url, { params });
  };

  patchUser = (params: Partial<OnboardingInfo>) => {
    const url = `${this.userUrl}/me`;

    return AxiosService.patch<OnboardingInfoResponse>(url, params);
  };
}

export default new UserRepository();
