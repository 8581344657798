import { PostmessageExternalSender } from '@3ps/postmessage-api-external';
import { ScanFormat } from '../types/PostMessageResponse/subtypes/ScanPostMessageResponse';

class PostMessageSenderService {
  private sender = new PostmessageExternalSender();

  public keepAlive = () => {
    this.sender.keepAlive();
  };

  public close = () => {
    this.sender.close();
  };

  public openUrl = (url: string) => {
    this.sender.openUrl(url);
  };

  public pageLoaded = () => {
    this.sender.pageLoaded();
  };

  public pageReady = () => {
    this.sender.pageReady();
  };

  public scan = () => {
    this.sender.scanBarcode(ScanFormat.QR_CODE);
  };
}

export default new PostMessageSenderService();
