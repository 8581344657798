import React from 'react';
import { Router } from 'react-router-dom';
import PostMessageProvider from './core/postMessage/providers/PostMessageProvider/PostMessageProvider';
import Shell from './core/shell/Shell';
import RouterOutlet from './core/router/RouterOutlet';
import { history } from './core/router/history/history';
import LanguageProvider from './i18n/components/LanguageProvider/LanguageProvider';

const App: React.FC = () => {
  return (
    <Router history={history}>
      <LanguageProvider>
        <PostMessageProvider>
          <Shell>
            <RouterOutlet />
          </Shell>
        </PostMessageProvider>
      </LanguageProvider>
    </Router>
  );
};

export default App;
