import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import GlobalSpinner from '../../shared/components/Spinners/GlobalSpinner/GlobalSpinner';
import { Routes } from './types/Routes';
import {
  LazyFindAMerchant,
  LazyHome,
  LazyScan,
  LazyShopDetail,
  LazyShopDetailWithCheckin,
  LazyShopDetailWithLoyaltyToken,
  LazyTermsAndConditions,
} from './types/RouterComponents';

const RouterOutlet: React.FC = () => {
  return (
    <Suspense fallback={<GlobalSpinner />}>
      <Switch>
        <Route path={Routes.HOME} component={LazyHome} />
        <Route path={Routes.SCAN} component={LazyScan} />
        <Route
          path={Routes.TERMS_AND_CONDITIONS}
          component={LazyTermsAndConditions}
        />
        <Route
          path={Routes.SHOP_DETAIL_WITH_CHECKIN}
          component={LazyShopDetailWithCheckin}
        />
        <Route
          path={Routes.SHOP_DETAIL_WITH_LOYALTY_TOKEN}
          component={LazyShopDetailWithLoyaltyToken}
        />
        <Route path={Routes.SHOP_DETAIL} component={LazyShopDetail} />
        <Route path={Routes.FIND_A_MERCHANT} component={LazyFindAMerchant} />
      </Switch>
    </Suspense>
  );
};

export default RouterOutlet;
