import getQueryParamFromUrl from '../../../shared/utils/getQueryParamFromUrl';
import LogService from '../../logger/services/LogService';
import getQueryParam from '../../../shared/utils/getQueryParam';

class QrProviderService {
  loadQrFromUrl = () => {
    const base64AdditionalData = getQueryParamFromUrl('kbcAdditionalData');

    if (!base64AdditionalData) {
      LogService.log('[QrProvider] Additional data missing');
      return;
    }

    LogService.log('[QrProvider] Additional data found', base64AdditionalData);
    const decodedAdditionalData = atob(base64AdditionalData);

    const qr = getQueryParam('QR', decodedAdditionalData);

    if (!qr || qr === 'undefined') {
      LogService.log('[QrProvider] Qr missing');
      return;
    }

    LogService.log('[QrProvider] Qr found', qr);
    return qr;
  };
}

export default new QrProviderService();
