import React, { useEffect } from 'react';
import PostMessageService from '../../../../services/PostMessageService';
import goBackOrExit from '../../../../../router/utils/goBackOrExit';

const BackHandler: React.FC = () => {
  useEffect(() => PostMessageService.onReceive.back(goBackOrExit), []);

  return null;
};

export default BackHandler;
