import React from 'react';
import VersionInfo from './components/VersionInfo/VersionInfo';

const Shell: React.FC = ({ children }) => {
  return (
    <React.Fragment>
      {children}

      <VersionInfo />
    </React.Fragment>
  );
};

export default Shell;
