import LogService from '../logger/services/LogService';
import TokenService from '../auth/services/TokenService';
import AnalyticsService from './AnalyticsService';

class AnalyticsServiceWrapper {
  initWithUserId = (): void => {
    const userId = TokenService.getUserIdFromToken();
    if (!userId) {
      LogService.log(
        `${AnalyticsService.logPrefix} Not initialised: No userId`,
      );
      return;
    }

    AnalyticsService.init(userId);
  };
}

export default new AnalyticsServiceWrapper();
