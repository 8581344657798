export enum Routes {
  ROOT = '/',
  HOME = '/home',
  SCAN = '/scan/:base64qr',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  SHOP_DETAIL = '/shop-detail/:id',
  SHOP_DETAIL_WITH_CHECKIN = '/shop-detail/:id/checkin',
  SHOP_DETAIL_WITH_LOYALTY_TOKEN = '/shop-detail/:id/loyalty-token/:points',
  FIND_A_MERCHANT = '/find-a-merchant',
}
