import React from 'react';
import KeepAliveHandler from './components/KeepAliveHandler/KeepAliveHandler';
import BackHandler from './components/BackHandler/BackHandler';
import KBCAuthCodeHandler from './components/KBCAuthCodeHandler/KBCAuthCodeHandler';
import ScanHandler from './components/ScanHandler/ScanHandler';

const PostMessageProvider: React.FC = ({ children }) => {
  return (
    <React.Fragment>
      <BackHandler />
      <KBCAuthCodeHandler />
      <ScanHandler />
      <KeepAliveHandler>{children}</KeepAliveHandler>
    </React.Fragment>
  );
};

export default PostMessageProvider;
