import React, { useState } from 'react';
import {
  APP_VERSION,
  BUILD_NUMBER,
  SENTRY_APP_RELEASE,
} from '../../../constants/constants';
import styles from './VersionInfo.module.scss';

const shortCommitHash = SENTRY_APP_RELEASE.substring(0, 7);

const VersionInfo: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapseState = () => setIsCollapsed(!isCollapsed);

  const buildNumberAndCommitHash = (
    <span>
      &nbsp;({BUILD_NUMBER}-{shortCommitHash})
    </span>
  );

  return (
    <span className={styles.versionInfo} onClick={toggleCollapseState}>
      v{APP_VERSION}
      {!isCollapsed ? buildNumberAndCommitHash : null}
    </span>
  );
};

export default VersionInfo;
