import React, { useEffect } from 'react';
import PostMessageService from '../../../../services/PostMessageService';
import KBCAuthCodeService from '../../../../services/KBCAuthCodeService';

const KBCAuthCodeHandler: React.FC = () => {
  useEffect(
    () =>
      PostMessageService.onReceive.KBCAuthCode(
        KBCAuthCodeService.loginWithKBCAuthCode,
      ),
    [],
  );

  useEffect(PostMessageService.send.pageLoaded, []);

  return null;
};

export default KBCAuthCodeHandler;
