import TokenService from '../../../core/auth/services/TokenService';
import TranslateService from '../../../i18n/services/TranslateService';
import { RequestHeaders } from './RequestHeaders';

const AcceptLanguage = 'accept-language';

class GlobalHeaders {
  authorization?: string;
  [AcceptLanguage]: string;

  constructor(requestHeaders: RequestHeaders) {
    this.setAuthorizationHeader(TokenService.token);
    this.setAcceptLanguageHeader(TranslateService.language);

    return {
      ...this,
      ...requestHeaders,
    };
  }

  private setAuthorizationHeader(token?: string) {
    if (token) {
      this.authorization = `Bearer ${token}`;
    } else {
      delete this.authorization;
    }
  }

  private setAcceptLanguageHeader(language: string) {
    this[AcceptLanguage] = language;
  }
}

export default GlobalHeaders;
