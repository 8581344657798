import {
  PostMessageResponse,
  PostMessageResponseType,
} from '../PostMessageResponse';

export enum ScanFormat {
  QR_CODE = 'QR_CODE',
}

export type ScanPostMessageResponse = PostMessageResponse<
  PostMessageResponseType.SCAN,
  {
    text: string;
    format: ScanFormat;
  }
>;
