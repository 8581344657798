import UserRepository from '../../../repository/user/UserRepository';
import { SessionInfo } from '../../../repository/user/types/SessionInfo';
import TokenService from '../../auth/services/TokenService';
import SentryService from '../../sentry/services/SentryService';
import OnboardingService from '../../onboarding/services/OnboardingService';
import PusherService from '../../pusher/services/PusherService';
import LogService from '../../logger/services/LogService';
import AnalyticsServiceWrapper from '../../analytics/AnalyticsServiceWrapper';
import PostMessageService from './PostMessageService';
import FlowPickerService from './FlowPickerService';

class KBCAuthCodeService {
  logPrefix = '[KBCAuthCodeService]';

  // 2020-03-26: The addition of a random hash to the url is a hack, specifically requested by KBC,
  // as a workaround for the broken history stack on iOS.
  // Adding this workaround should allow the history.goBack() function to work as expected.
  // 2020-06-16: The history hack has been moved from a useEffect hook in KBCAuthCodeHandler,
  // after sending the pageLoaded event, to this service.
  // It will now be applied right before sending the pageReady event.
  applyKBCMobileHistoryHack = (): void => {
    LogService.log(`${this.logPrefix} Applying #kbcMobileHistoryHack`);
    window.location.assign(window.location.href + '#kbcMobileHistoryHack');
  };

  loginWithKBCAuthCode = async (authCode: string): Promise<void> => {
    LogService.log(`${this.logPrefix} Start`);

    try {
      const {
        token,
        pusherChannel,
        showTermsAndConditions,
      }: SessionInfo = await UserRepository.authorizeWithKBCAuthCode(authCode);

      TokenService.token = token;
      OnboardingService.showTermsAndConditions = showTermsAndConditions;

      PusherService.init(pusherChannel, token);
      AnalyticsServiceWrapper.initWithUserId();

      FlowPickerService.chooseFlow();

      this.applyKBCMobileHistoryHack();

      LogService.log(`${this.logPrefix} Done`);
      PostMessageService.send.pageReady();
    } catch (e) {
      LogService.warn(`${this.logPrefix} Error occurred!`);

      SentryService.captureException(e);
      PostMessageService.send.close();
    }
  };
}

export default new KBCAuthCodeService();
