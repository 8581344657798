import qs from 'query-string';

const getQueryParam = (key: string, queryString: string) => {
  const params = qs.parse(queryString);
  const value = params[key];

  if (typeof value === 'string') {
    return value;
  }

  return;
};

export default getQueryParam;
