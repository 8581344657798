import { SupportedLocale } from '../locale';
import { equalsIgnoreCase } from '../../shared/utils/equalsIgnoreCase';
import Mlo from './Mlo';
import MloItem from './MloItem';

class MloTranslator {
  constructor(private mlo: Mlo, private language: SupportedLocale) {}

  private get currentLanguageMloItem(): MloItem | undefined {
    return this.mlo.find(({ language }) => {
      return equalsIgnoreCase(language, this.language);
    });
  }

  private get anyContentMloItem(): MloItem | undefined {
    return this.mlo.find(({ content }) => content);
  }

  private get defaultMloItem(): MloItem {
    return { language: this.language, content: '' };
  }

  private get mloItem(): MloItem {
    return (
      this.currentLanguageMloItem ||
      this.anyContentMloItem ||
      this.defaultMloItem
    );
  }

  private get translation(): string {
    return this.mloItem.content;
  }

  static t = (mlo: Mlo, language: SupportedLocale) => {
    return new MloTranslator(mlo, language).translation;
  };
}

export default MloTranslator;
