import React from 'react';
import PostMessageService from '../../../../services/PostMessageService';

const KeepAliveHandler: React.FC = ({ children }) => {
  const keepAlive = () => PostMessageService.send.keepAlive();

  return <div onClick={keepAlive}>{children}</div>;
};

export default KeepAliveHandler;
